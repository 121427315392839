$cinabarColor: #e1463f;

.navigation-page {
  .menu-toggle {
    .icon {
      position: absolute;
      display: inline-block;
      top: 0;
      left: 0;
      width: 100%;
      height: 3px;
      background-color: $cinabarColor;
      -webkit-transition: all 0.4s ease;
      transition: all 0.4s ease;

      &:before {
        top: 1rem;
      }

      &:after {
        top: 2rem;
      }

      &:after,
      &:before {
        content: "";
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $cinabarColor;
        -webkit-transform-origin: center;
        -moz-transform-origin: center;
        -ms-transform-origin: center;
        transform-origin: center;
        -webkit-transition: all 0.4s ease;
        transition: all 0.4s ease;
      }
    }

    &[aria-expanded="true"] {
      left: 270px;
      transition: left 0.3s ease-out;

      .icon {
        background-color: transparent;

        &:before {
          -webkit-transform: rotate(45deg) scaleX(1.4);
          -moz-transform: rotate(45deg) scaleX(1.4);
          -ms-transform: rotate(45deg) scaleX(1.4);
          transform: rotate(45deg) scaleX(1.4);
        }

        &:after {
          top: 1rem;
          -webkit-transform: rotate(-45deg) scaleX(1.4);
          -moz-transform: rotate(-45deg) scaleX(1.4);
          -ms-transform: rotate(-45deg) scaleX(1.4);
          transform: rotate(-45deg) scaleX(1.4);
        }
      }
    }
  }

  .nav-items {
    .contant-info {
      margin-bottom: 4rem;

      div {
        font-family: "Lalezar", cursive;
        font-size: 1.3rem;
        color: #fff;

        a {
          font-family: "Lalezar", cursive;
          font-size: 1.3rem;
          color: #fff;
          -webkit-transition: all 0.3s ease;
          transition: all 0.3s ease;
          &:focus,
          &:hover {
            color: #eeb642;
            text-decoration: none;
          }
        }
      }
    }

    .menu-links {
      margin-bottom: 6rem;

      ul {
        padding: 0;
        margin: 0;
        list-style: none;

        li {
          overflow: hidden;
          padding-top: 0.5rem;

          a {
            display: inline-block;
            font-size: 3.3rem;
            line-height: 3.4rem;
            font-family: "Lalezar", cursive;
            color: #fff;
            text-transform: uppercase;
            -webkit-transition: all 0.5s ease;
            transition: all 0.5s ease;

            &:focus,
            &:hover {
              color: #eeb642;
              text-decoration: none;
            }
          }
        }
      }
    }
    .social-media .social-link-holder {
      text-align: left;
    }

    .social-media .social-link-holder a {
      font-size: 1.2rem;
      font-family: "Lalezar", cursive;
      color: #fff;
      -webkit-transition: all 0.3s ease;
      transition: all 0.3s ease;
    }

    .social-media .social-link-holder a:hover,
    .social-media .social-link-holder a:focus {
      color: #eeb642;
      text-decoration: none;
    }

    .social-media.hide {
      -webkit-transform: translateY(-13rem);
      -moz-transform: translateY(-13rem);
      -ms-transform: translateY(-13rem);
      transform: translateY(-13rem);
    }
  }

  .uk-offcanvas-bar {
    padding: 10px 20px;
  }

  .cnav {
    position: fixed;
    width: 100%;
    z-index: 999;
    padding: 4rem 3.5rem;
    pointer-events: none;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;

    .logo-holder {
      display: inline-block;
      pointer-events: all;

      img {
        width: 7.4rem;
      }
    }

    .social-media {
      display: inline-block;
      opacity: 1;
      pointer-events: all;
      -webkit-transform: translateY(0);
      -moz-transform: translateY(0);
      -ms-transform: translateY(0);
      transform: translateY(0);
      -webkit-transition: all 0.3s ease;
      transition: all 0.3s ease;

      .social-link-holder {
        text-align: left;

        a {
          font-size: 1.2rem;
          font-family: "Lalezar", cursive;
          color: #fff;
          -webkit-transition: all 0.3s ease;
          transition: all 0.3s ease;

          &:focus,
          &:hover {
            color: #eeb642;
            text-decoration: none;
          }
        }
      }

      &.hide {
        -webkit-transform: translateY(-13rem);
        -moz-transform: translateY(-13rem);
        -ms-transform: translateY(-13rem);
        transform: translateY(-13rem);
      }
    }
  }

  @media (max-width: 1200px) {
    body {
      &[aria-expanded="true"] {
        padding-left: 0;
      }
    }
  }

  @media (max-width: 575px) {
    .menu-toggle {
      left: inherit;
      right: 1.5rem;
      top: 3.1rem;
    }
  }

  @media (max-width: 768px) {
    .menu-toggle {
      // left: 2rem;
    }
  }

  @media (max-width: 575px) {
    .cnav .logo-holder img {
      width: 2rem;
    }
  }

  @media (max-width: 768px) {
    .cnav .logo-holder img {
      // width: 2.2em;
      width: 8em;
    }
  }
}
