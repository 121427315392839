.footer {
  background-color: #232931;
  text-align: center;
  padding: 10rem 8rem 8rem;
}

.footer .lg-text {
  color: #eeb642;
  margin-bottom: 2.2rem;
}

.footer p {
  color: rgba(255, 255, 255, 0.66);
  font-size: 1.26rem;
  line-height: 2.5rem;
}

.footer .normal-text {
  margin-bottom: 6rem;
}

.footer .contact-info-holder {
  text-align: left;
}

.footer .contact-info-holder .title {
  font-family: "Lalezar", cursive;
  font-size: 1.6rem;
  color: #777a80;
}

.footer .contact-info-holder .contact-info {
  font-family: "Lalezar", cursive;
  color: #fff;
  font-size: 4rem;
}

.footer .contact-info-holder .contact-info a {
  font-family: "Lalezar", cursive;
  color: #fff;
  font-size: 4rem;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.footer .contact-info-holder .contact-info a:hover,
.footer .contact-info-holder .contact-info a:focus {
  // color: #4ECCA3;
  color: #eeb642;
  text-decoration: none;
}

.footer .contact-info-holder .social-media {
  display: inline-block;
  opacity: 1;
  pointer-events: all;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.footer .contact-info-holder .social-media .social-link-holder {
  text-align: left;
  display: inline-block;
  margin-right: 0.5rem;
}

.footer .contact-info-holder .social-media .social-link-holder a {
  font-size: 1.2rem;
  font-family: "Lalezar", cursive;
  color: #fff;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.footer .contact-info-holder .social-media .social-link-holder a:hover,
.footer .contact-info-holder .social-media .social-link-holder a:focus {
  color: #eeb642;
  text-decoration: none;
}

.footer.one-word {
  padding: 14rem 8rem 13rem;
}

.footer.one-word .lg-text {
  color: #fff;
}

@media (max-width: 1200px) {
  .footer .contact-info-holder .contact-info,
  .few-contact .contact-info-holder .contact-info {
    font-size: 4rem;
  }

  .footer .contact-info-holder .contact-info a,
  .few-contact .contact-info-holder .contact-info a {
    font-size: 4rem;
  }
}

@media (max-width: 768px) {
  .footer {
    padding: 6rem 2rem 6rem;
  }
}
