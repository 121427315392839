@import "nav/navbar.scss";
@import "footer/footer.scss";

@import url("https://fonts.googleapis.com/css?family=Comfortaa&display=swap");
@import url("https://fonts.googleapis.com/css?family=Lalezar&display=swap");
@import url("https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/css/bootstrap.min.css");
@import url("https://cdn.jsdelivr.net/npm/uikit@3.13.10/dist/css/uikit.min.css");

//
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

/* 
tk color codes
eeb642
e0493e red
545459
428fb7 blue
*/

$brightYellow: #ffaa2a;
$blackColor: #000000;
$liverDoga: #bf6a00;
$cinabarColor: #e1463f;

::selection {
  background-color: rgba(137, 139, 145, 0.7);
}

*,
body {
  font-family: "Comfortaa", cursive;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Lalezar", cursive;
}

.cr-btn {
  display: inline-block;
  font-family: "Lalezar", cursive;
  font-size: 1.45rem;
  color: #fff;
  text-transform: uppercase;
  padding: 1.3rem 2.1rem 1rem;
  border-radius: 10rem;
  // -webkit-box-shadow: inset 0 0 0 0.25rem #fff;
  // box-shadow: inset 0 0 0 0.25rem #fff;

  -webkit-box-shadow: inset 0 0 0 0.25rem $brightYellow;
  box-shadow: inset 0 0 0 0.25rem $brightYellow;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.cr-btn:hover,
.cr-btn:focus {
  // color: #eeb642;
  color: $liverDoga;
  text-decoration: none;
  -webkit-box-shadow: inset 0 0 0 3.25rem #fff;
  box-shadow: inset 0 0 0 3.25rem #fff;
}

.cr-btn.primary {
  color: $blackColor;
  // -webkit-box-shadow: inset 0 0 0 0.28rem #eeb642;
  // box-shadow: inset 0 0 0 0.28rem #eeb642;
  -webkit-box-shadow: inset 0 0 0 0.28rem $blackColor;
  box-shadow: inset 0 0 0 0.28rem $blackColor;
  // added
  background-color: #fff;
}

.cr-btn.primary:hover,
.cr-btn.primary:focus {
  color: #fff;
  -webkit-box-shadow: inset 0 0 0 3.25rem #eeb642;
  box-shadow: inset 0 0 0 3.25rem #eeb642;
}

.cr-btn.black {
  color: #000;
  -webkit-box-shadow: inset 0 0 0 0.28rem #000;
  box-shadow: inset 0 0 0 0.28rem #000;
}

.cr-btn.black:hover,
.cr-btn.black:focus {
  color: #eeb642;
  -webkit-box-shadow: inset 0 0 0 3.25rem #000;
  box-shadow: inset 0 0 0 3.25rem #000;
}

.cr-btn.ex-padding {
  padding: 1.3rem 3.7rem 1rem;
}

body {
  overflow-x: hidden;
  -webkit-transition: all 500ms cubic-bezier(1, -0.02, 0.25, 0.75);
  transition: all 500ms cubic-bezier(1, -0.02, 0.25, 0.75);
}

body.menu-open {
  padding-left: 30rem;
}

/* important */
.menu-toggle {
  position: fixed;
  z-index: 99999;
  width: 1.8rem;
  height: 2.2rem;
  cursor: pointer;
  left: 3.5rem;
  top: 50%;
  margin-top: -1.1rem;
  mix-blend-mode: normal; //prevents icon from changing color
  -webkit-transition: all 500ms cubic-bezier(1, -0.02, 0.25, 0.75);
  transition: all 500ms cubic-bezier(1, -0.02, 0.25, 0.75);
}

.cnav.blend {
  mix-blend-mode: normal;
}

.header {
  background-color: #232931;
  text-align: center;
  padding: 16rem 3.5rem 14rem;
}

.header .mouse-scroll {
  position: absolute;
  display: inline-block;
  bottom: 3rem;
  left: 50%;
  margin-left: -0.8rem;
  width: 1.6rem;
  height: 2.4rem;
  border-radius: 2rem;
  background-color: rgba(255, 255, 255, 0.15);
  pointer-events: none;
  -webkit-box-shadow: 0 0.3rem 5rem rgba(0, 0, 0, 0.24);
  box-shadow: 0 0.3rem 5rem rgba(0, 0, 0, 0.24);
  -webkit-transition: all 0.6s ease;
  transition: all 0.6s ease;
}

.header .mouse-scroll:before {
  position: absolute;
  content: "";
  top: 0.5rem;
  left: 50%;
  margin-left: -0.1rem;
  width: 0.2rem;
  height: 0.4rem;
  border-radius: 1rem;
  background-color: rgba(255, 255, 255, 0.21);
  -webkit-animation: m-an 2s ease infinite;
  -moz-animation: m-an 2s ease infinite;
  -ms-animation: m-an 2s ease infinite;
  animation: m-an 2s ease infinite;
}

.header .mouse-scroll.hide {
  opacity: 0;
}

.header .mouse-scroll.hide:before {
  opacity: 0;
  -webkit-animation: none;
  -moz-animation: none;
  -ms-animation: none;
  animation: none;
  -webkit-transition: all 0.6s ease;
  transition: all 0.6s ease;
}
.header .mouse-scroll:hover {
  cursor: pointer !important;
}

@-webkit-keyframes m-an {
  0% {
    top: 0.5rem;
    height: 0.2rem;
    opacity: 0;
  }

  15% {
    top: 0.7rem;
    height: 0.6rem;
    opacity: 1;
  }

  50% {
    top: 0.9rem;
    height: 0.6rem;
    opacity: 1;
  }

  70% {
    top: 1rem;
    height: 0.5rem;
    opacity: 1;
  }

  90% {
    top: 1.2rem;
    height: 0.5rem;
    opacity: 0;
  }

  100% {
    top: 1.2rem;
    height: 0.5rem;
    opacity: 0;
  }
}

@-moz-keyframes m-an {
  0% {
    top: 0.5rem;
    height: 0.2rem;
    opacity: 0;
  }

  15% {
    top: 0.7rem;
    height: 0.6rem;
    opacity: 1;
  }

  50% {
    top: 0.9rem;
    height: 0.6rem;
    opacity: 1;
  }

  70% {
    top: 1rem;
    height: 0.5rem;
    opacity: 1;
  }

  90% {
    top: 1.2rem;
    height: 0.5rem;
    opacity: 0;
  }

  100% {
    top: 1.2rem;
    height: 0.5rem;
    opacity: 0;
  }
}

@-ms-keyframes m-an {
  0% {
    top: 0.5rem;
    height: 0.2rem;
    opacity: 0;
  }

  15% {
    top: 0.7rem;
    height: 0.6rem;
    opacity: 1;
  }

  50% {
    top: 0.9rem;
    height: 0.6rem;
    opacity: 1;
  }

  70% {
    top: 1rem;
    height: 0.5rem;
    opacity: 1;
  }

  90% {
    top: 1.2rem;
    height: 0.5rem;
    opacity: 0;
  }

  100% {
    top: 1.2rem;
    height: 0.5rem;
    opacity: 0;
  }
}

@keyframes m-an {
  0% {
    top: 0.5rem;
    height: 0.2rem;
    opacity: 0;
  }

  15% {
    top: 0.7rem;
    height: 0.6rem;
    opacity: 1;
  }

  50% {
    top: 0.9rem;
    height: 0.6rem;
    opacity: 1;
  }

  70% {
    top: 1rem;
    height: 0.5rem;
    opacity: 1;
  }

  90% {
    top: 1.2rem;
    height: 0.5rem;
    opacity: 0;
  }

  100% {
    top: 1.2rem;
    height: 0.5rem;
    opacity: 0;
  }
}

.extra-lg-text {
  color: $liverDoga;
  text-align: center;
  font-family: "Lalezar", cursive;
  font-size: 6.9rem;
  line-height: 5rem;
  text-transform: uppercase;
}

.extra-lg-text span {
  display: inline-block;
  font-family: "Lalezar", cursive;
}

.extra-lg-text .other-color {
  /* color: #4ECCA3; */
  color: #e0493e;
  font-family: "Lalezar", cursive;
}

.boxy .text ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.boxy .text ul li {
  // font-family: "Lalezar", cursive;
  text-transform: none;
  font-size: 1.2rem;
}

.inner-page .header {
  background-color: #393e46;
}

/* -----------------------------------
    3 - Box Contentt Styles
------------------------------------*/
.box-content {
  padding: 0;
}

.box-content .row {
  margin: 0;
}

.box-content .row div[class^="col"] {
  padding: 0;
}

.boxy {
  position: relative;
  height: 100%;
  padding: 12rem 6rem;
}

.boxy h1.title {
  font-size: 4.1rem;
  line-height: 3.4rem;
  margin: 0;
  margin-bottom: 3rem;
}

.boxy .text ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.boxy .text ul li {
  // font-family: "Lalezar", cursive;
  // text-transform: uppercase;
  // font-size: 1.2rem;
  font-family: "Roboto";
  text-transform: capitalize;
  font-size: 1.2rem;
}

.boxy .bottom-text {
  text-align: center;
}

.boxy .bottom-text .link {
  font-family: "Lalezar", cursive;
  text-transform: uppercase;
  color: #fff;
  font-size: 1.3rem;
}

.boxy .bottom-text .link a {
  font-family: "Lalezar", cursive;
  text-transform: uppercase;
  color: #fff;
  font-size: 1.3rem;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.boxy .bottom-text .link a:hover,
.boxy .bottom-text .link a:focus {
  color: #eeb642;
  text-decoration: none;
}

.boxy .bottom-text .text {
  color: #fff;
  font-size: 1.2rem;
  font-family: "Roboto";
}

.boxy .project-link-full {
  position: absolute;
  display: inline-block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.boxy.c1-color {
  background-color: $brightYellow;
}

.boxy.c1-color h1.title {
  color: #232931;
}

.boxy.c1-color .text {
  color: rgba(0, 0, 0, 0.66);
}

.boxy.c1-color .slg-text {
  color: #232931;
}

.boxy.c1-color .normal-lg-text p {
  color: rgba(0, 0, 0, 0.66);
}

.boxy.primary-color {
  background-color: #4ecca3;
}
.boxy.form-color {
  background-color: #428fb7;
}
.uk-form-label {
  color: rgba(255, 255, 255, 0.66);
  font-size: 1.26rem;
  line-height: 2.5rem;
  font-family: "Roboto";
}

// 428fb7

.boxy.primary-color h1.title {
  color: #232931;
}

.boxy.primary-color .text {
  color: rgba(0, 0, 0, 0.66);
}

.boxy.primary-color .slg-text {
  color: #fff;
}

.boxy.primary-color .normal-lg-text p {
  color: #fff;
}

.boxy.default-color {
  background-color: $blackColor;
}

.boxy.default-color h1.title {
  color: #eeeeee;
}

.boxy.default-color .text {
  color: rgba(216, 216, 216, 0.66);
}

.boxy.default-color .slg-text {
  color: #fff;
}

.boxy.default-color .normal-lg-text p {
  color: rgba(255, 255, 255, 0.66);
}

.boxy.c2-color {
  background-color: $liverDoga;
}

.boxy.c2-color h1.title {
  color: #eeeeee;
}

.boxy.c2-color .text {
  color: rgba(216, 216, 216, 0.66);
}

.boxy.c2-color .slg-text {
  color: #fff;
}

.boxy.c2-color .normal-lg-text p {
  color: rgba(255, 255, 255, 0.66);
}
//
.boxy.form-color h1.title {
  color: #eeeeee;
}

.boxy.form-color .text {
  color: rgba(216, 216, 216, 0.66);
}

.boxy.form-color.slg-text {
  color: #fff;
}

.boxy.form-color .normal-lg-text p {
  color: rgba(255, 255, 255, 0.66);
}
//

.boxy.img-box {
  position: relative;
  padding: 4rem;
  min-height: 652px;
  z-index: 1;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -moz-justify-content: center;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -moz-align-items: flex-end;
  -webkit-align-items: flex-end;
  -ms-align-items: flex-end;
  align-items: flex-end;
  overflow: hidden;
}

.boxy.img-box:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #232931;
  opacity: 0;
  z-index: -1;
  -webkit-transform-origin: center center;
  -moz-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center;
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  -ms-transform: scale(0.9);
  transform: scale(0.9);
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.boxy.img-box .img img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.boxy.img-box .img {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-transform-origin: center center;
  -moz-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}

.boxy.img-box:hover .img,
.boxy.img-box:focus .img {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.boxy.img-box:hover:before,
.boxy.img-box:focus:before {
  opacity: 0.8;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

/* -----------------------------------
    4 - Default Content Styles
------------------------------------*/
.default-content {
  background-color: #232931;
  text-align: center;
  padding: 10rem 3.5rem 8rem;
}

.default-content .lg-text {
  margin-bottom: 2.2rem;
}

.default-content p {
  color: rgba(255, 255, 255, 0.66);
  font-size: 1.26rem;
  line-height: 2.5rem;
}

.default-content .normal-text {
  margin-bottom: 5rem;
}

.lg-text {
  /* color: #e0493e; */
  /* color: #fff;********************************************************************************************************* */
  color: #fff;
  text-align: center;
  font-family: "Roboto";
  font-size: 5.3rem;
  line-height: 3.9rem;
  text-transform: uppercase;
  margin-bottom: 2.2rem;
}

.lg-text span {
  font-family: "Lalezar", cursive;
  display: inline-block;
}

.lg-text .other-color {
  /* color: #4ECCA3; */
  color: #e0493e;
  font-family: "Lalezar", cursive;
}

.normal-text p {
  color: rgba(255, 255, 255, 0.66);
  font-size: 1.26rem;
  line-height: 2.5rem;
  font-family: "Roboto";
}

.slg-text {
  color: #fff;
  text-align: center;
  font-family: "Roboto";
  font-size: 5rem;
  line-height: 3.9rem;
  text-transform: uppercase;
  margin-bottom: 5rem;
}

.slg-text span {
  font-family: "Lalezar", cursive;
  display: inline-block;
}

.slg-text .other-color {
  /* color: #4ECCA3; */
  color: #e0493e;
  font-family: "Lalezar", cursive;
}

.normal-lg-text {
  text-align: center;
}

.normal-lg-text p {
  font-family: "Lalezar", cursive;
  color: rgba(255, 255, 255, 0.66);
  font-size: 2.5rem;
  line-height: 3.3rem;
}

.normal-lg-text p a {
  font-family: "Lalezar", cursive;
  color: rgba(255, 255, 255, 0.66);
  font-size: 2rem;
  line-height: 3.3rem;
}

/* -----------------------------------
    5 - Clients Section Styles
------------------------------------*/
.clients-section {
  background-color: #fff;
  text-align: left;
  padding: 10rem 10rem 3rem;
}

.clients-section .lg-text {
  color: #000;
  text-align: left;
  margin-bottom: 2.2rem;
}

.clients-section p {
  color: rgba(0, 0, 0, 0.66);
  font-size: 1.26rem;
  line-height: 2.5rem;
}

.clients-section .normal-text {
  margin-bottom: 5rem;
}

.clients-section .clients-logos {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  width: 100%;
}

.clients-section .clients-logos .logo-holder {
  -moz-flex: 1 1 25%;
  -webkit-flex: 1 1 25%;
  -ms-flex: 1 1 25%;
  flex: 1 1 25%;
  margin-bottom: 5rem;
}

.clients-section .clients-logos .logo-holder img {
  // max-height: 2.8rem;
  height: 10rem;
}

/* -----------------------------------
    6 - Other Content Styles
------------------------------------*/
.other-content {
  background-color: #393e46;
  text-align: center;
  padding: 9rem 3.5rem 7rem;
}

.other-content .lg-text {
  margin-bottom: 2.2rem;
  font-family: "Lalezar", cursive;
  // font-size: 4.8rem;
}

.other-content p {
  color: rgba(255, 255, 255, 0.66);
  font-size: 1.26rem;
  line-height: 2.5rem;
}

.other-content .normal-text {
  margin-bottom: 4rem;
}

/* -----------------------------------
    7 - Process Section Styles
------------------------------------*/
.process-section {
  background-color: #fff;
  text-align: left;
  padding: 8rem 12rem 3rem;
}

.process-section .lg-text {
  text-align: left;
}

.process-section p {
  color: rgba(0, 0, 0, 0.66);
  margin-bottom: 6rem;
}

.process-section > .row {
  margin-right: -40px;
  margin-left: -40px;
}

.process-section > .row > div[class^="col"] {
  padding-right: 40px;
  padding-left: 40px;
}

.text-box {
  margin-bottom: 4rem;
}

.text-box .title {
  font-family: "Lalezar", cursive;
  font-size: 2.4rem;
  margin-bottom: 0.8rem;
}

.text-box p {
  color: rgba(0, 0, 0, 0.66);
  font-size: 1.26rem;
  line-height: 2.5rem;
  font-family: "Roboto";
}

/* -----------------------------------
    8 - Team Section Styles
------------------------------------*/
.team-section {
  position: relative;
  background-color: #393e46;
  padding: 10rem 8rem 12rem;
  z-index: 10;
}

/* new sliding team starts*/
.team-slider {
  position: absolute;
  left: 0;
  bottom: -12.25rem;
  width: 100%;
  padding-bottom: 5rem;
  overflow: hidden;
  white-space: nowrap;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.team-slide-track {
  display: flex;
  width: calc(250px * 12);
  animation: scroll 40s linear infinite;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-250px * 6));
  }
}

.team-slide-track:hover {
  animation-play-state: paused;
}

.team-slide {
  height: 200px;
  width: 250px;
  // was out
  display: flex;
  align-items: center;
  padding: 15px;
  perspective: 100px;
  position: relative;
  display: inline-block;
  margin: 0 2.4rem;
}

.team-slide:nth-child(odd) {
  bottom: -3.5rem;
}

.team-image {
  width: 100%;
  transition: transform 1s;
  border-radius: 50%;
  border: 1px solid grey;
}

.team-image:hover {
  transform: translateZ(10px);
}

/* .team-slider::before,
.team-slider::after {
    background: linear-gradient(to right, rgba(255, 255, 255, 1)0%, rgba(255, 255, 255, 0)100%);
    content: '';
    height: 100%;
    position: absolute;
    width: 15%;
    z-index: 2;
} */

.team-slider::before {
  left: 0;
  top: 0;
}

.team-slider::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}

/* new sliding team ends */

.team-section .team-photos {
  position: absolute;
  left: 0;
  bottom: -12.25rem;
  width: 100%;
  padding-bottom: 5rem;
  overflow: hidden;
  white-space: nowrap;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.team-section .team-photos .photo-holder {
  position: relative;
  display: inline-block;
  margin: 0 2.4rem;
}

.team-section .team-photos .photo-holder img {
  border-radius: 10rem;
  width: 14.5rem;
}

.team-section .team-photos .photo-holder:nth-child(odd) {
  bottom: -3.5rem;
}

@-webkit-keyframes photos-move {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    -webkit-transform: translateX(-140%);
    -moz-transform: translateX(-140%);
    -ms-transform: translateX(-140%);
    transform: translateX(-140%);
  }
}

@-moz-keyframes photos-move {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    -webkit-transform: translateX(-140%);
    -moz-transform: translateX(-140%);
    -ms-transform: translateX(-140%);
    transform: translateX(-140%);
  }
}

@-ms-keyframes photos-move {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    -webkit-transform: translateX(-140%);
    -moz-transform: translateX(-140%);
    -ms-transform: translateX(-140%);
    transform: translateX(-140%);
  }
}

@keyframes photos-move {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    -webkit-transform: translateX(-140%);
    -moz-transform: translateX(-140%);
    -ms-transform: translateX(-140%);
    transform: translateX(-140%);
  }
}

/* -----------------------------------
    9 - Jobs Section Styles
------------------------------------*/
.jobs-section {
  background-color: #fff;
  text-align: left;
  padding: 10rem 10rem 3rem;
}

.jobs-section .lg-text {
  text-align: left;
  margin-bottom: 7rem;
}

.jobs-section .job-box-row {
  margin-bottom: 5rem;
}

.jobs-section .job-box-row:last-child {
  margin-bottom: 0;
}

.jobs-section .job-box .title {
  font-family: "Lalezar", cursive;
  font-size: 2.4rem;
  line-height: 2.5rem;
  text-transform: uppercase;
}

.jobs-section .job-box .subtitle {
  color: rgba(0, 0, 0, 0.66);
  font-size: 1.26rem;
  line-height: 2.5rem;
}

.jobs-section .btn-holder {
  text-align: right;
}

.padding-for-team {
  padding-top: 17rem !important;
}

.no-padding-bottom {
  padding-bottom: 0 !important;
}

/* -----------------------------------
    10 - Blog Section Styles
------------------------------------*/
.blog-section {
  padding: 6rem;
  background-color: #fff;
}

.post-box {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  background-color: #eeeeee;
  margin-bottom: 5rem;
}

.post-box:last-child {
  margin-bottom: 0;
}

.post-box .text-holder {
  padding: 6.5rem 5rem;
}

.post-box .text-holder .title {
  display: inline-block;
  color: #232931;
  text-align: left;
  font-family: "Lalezar", cursive;
  font-size: 4rem;
  line-height: 3.9rem;
  text-transform: uppercase;
  margin-bottom: 2.2rem;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.post-box .text-holder .title:hover,
.post-box .text-holder .title:focus {
  text-decoration: none;
  color: #60666d;
}

.post-box .text-holder .text {
  color: rgba(0, 0, 0, 0.66);
  font-size: 1.26rem;
  line-height: 2.5rem;
  // added
  font-family: "Roboto";
  p {
    // added
    font-family: "Roboto";
  }
}

.post-box .img-holder img {
  width: 29rem;
  // width: 60%;
}

/* -----------------------------------
    11 - Post Section Styles
------------------------------------*/
.post-section {
  background-color: #fff;
  padding: 0;
}

.post-section > .row {
  margin: 0;
}

.post-section > .row > div[class^="col"] {
  padding: 0;
}

.post-section .post-header {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.post-section .post-header .post-info-holder {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  margin-bottom: 4rem;
}

.post-section .post-header .post-info-holder .link-holder {
  -moz-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.post-section .post-header .post-info-holder .link-holder a {
  font-family: "Lalezar", cursive;
  font-size: 1.7rem;
  color: #393e46;
  text-transform: uppercase;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.post-section .post-header .post-info-holder .link-holder a:hover,
.post-section .post-header .post-info-holder .link-holder a:focus {
  color: #eeb642;
  text-decoration: none;
}

.post-section .post-header .post-info-holder .post-info {
  -moz-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  text-align: right;
}

.post-section .post-header .post-info-holder .post-info .date {
  display: inline-block;
  color: rgba(0, 0, 0, 0.66);
  font-size: 1.26rem;
  margin-right: 1rem;
}

.post-section .post-header .post-info-holder .post-info .auther {
  display: inline-block;
  color: rgba(0, 0, 0, 0.66);
  font-size: 1.26rem;
}

.post-section .post-header .text-holder {
  padding: 4rem 9.5rem;
}

.post-section .post-header .text-holder .title {
  display: inline-block;
  color: #232931;
  text-align: left;
  font-family: "Lalezar", cursive;
  font-size: 5.3rem;
  line-height: 3.9rem;
  text-transform: uppercase;
  margin-bottom: 2.2rem;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.post-section .post-header .text-holder .text {
  color: rgba(0, 0, 0, 0.66);
  font-size: 1.26rem;
  line-height: 2.5rem;
}

.post-section .post-header .img-holder img {
  width: 29rem;
}

.post-section .post-content {
  padding: 4rem 9.5rem;
}

.post-section .post-content h1 {
  margin-bottom: 1rem;
}

.post-section .post-content {
  p {
    color: rgba(0, 0, 0, 0.66);
    font-size: 1.12rem;
    line-height: 2.5rem;
    margin-bottom: 4rem;
    font-family: "Roboto" !important;
  }
  li {
    color: rgba(0, 0, 0, 0.66);
    font-size: 1.12rem;
    line-height: 2.5rem;
    font-family: "Roboto" !important;
  }
}

.post-section {
  p {
    color: rgba(0, 0, 0, 0.66);
    font-size: 1.12rem;
    line-height: 2.5rem;
    font-family: "Roboto" !important;
  }
}
/* -----------------------------------
    12 - Few Contact Styles
------------------------------------*/
.few-contact {
  padding: 4rem 8rem;
  background-color: #fff;
}

.few-contact .contact-info-holder {
  text-align: left;
}

.few-contact .contact-info-holder .title {
  font-family: "Lalezar", cursive;
  font-size: 1.6rem;
  color: #777a80;
}

.few-contact .contact-info-holder .contact-info {
  font-family: "Lalezar", cursive;
  color: #232931;
  font-size: 4rem;
}

.few-contact .contact-info-holder .contact-info a {
  font-family: "Lalezar", cursive;
  color: #eeb642;
  font-size: 4rem;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.few-contact .contact-info-holder .contact-info a:hover,
.few-contact .contact-info-holder .contact-info a:focus {
  color: #eeb642;
  text-decoration: none;
}

/* -----------------------------------
    13 - Jobs Section Styles
------------------------------------*/
.our-people-section {
  background-color: #fff;
  text-align: left;
  padding: 10rem 10rem;
}

.our-people-section .extra-lg-text {
  color: #393e46;
  margin-bottom: 11rem;
}

.our-people-section .lg-text {
  text-align: left;
  margin-bottom: 2rem;
}

.our-people-section .normal-text {
  margin-bottom: 5rem;
}

.our-people-section .normal-text p {
  color: rgba(0, 0, 0, 0.66);
}

.our-people-section .people-box-row {
  margin-bottom: 5rem;
}

.our-people-section .people-box-row:last-child {
  margin-bottom: 0;
}

.our-people-section .people-box .title {
  font-family: "Lalezar", cursive;
  font-size: 2.4rem;
  line-height: 2.5rem;
  text-transform: uppercase;
}

.our-people-section .people-box .subtitle {
  color: rgba(0, 0, 0, 0.66);
  font-size: 1.26rem;
  line-height: 2.5rem;
}

.our-people-section .btn-holder {
  text-align: right;
}

/* -----------------------------------
    14 - Social Row Styles
------------------------------------*/
.social-row {
  background-color: #393e46;
  text-align: center;
  padding: 5rem 5.5rem;
}

.social-row .social-media .social-link-holder {
  display: inline-block;
  margin: 0 1rem;
}

.social-row .social-media .social-link-holder a {
  font-family: "Lalezar", cursive;
  font-size: 2.5rem;
  color: #fff;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.social-row .social-media .social-link-holder a:hover,
.social-row .social-media .social-link-holder a:focus {
  color: #eeb642;
  text-decoration: none;
}

/* -----------------------------------
    15 - Project Info Styles
------------------------------------*/
.project-info {
  background-color: #fff;
  padding: 6rem 8rem 2rem;
}

.project-info h2 {
  color: #393e46;
  margin-bottom: 1rem;
}

.project-info .lg-text {
  color: #000;
  text-align: left;
  margin-bottom: 2rem;
}

.project-info .normal-text {
  margin-bottom: 5rem;
}

.project-info .normal-text p {
  color: rgba(0, 0, 0, 0.66);
}

.project-info .text-box .title {
  color: #393e46;
  font-size: 1.9rem;
  margin-bottom: 1.4rem;
}

.project-imgs {
  background-color: #fff;
  padding: 3.5rem;
}

.project-imgs .img-holder {
  margin-bottom: 4rem;
}

.project-imgs .img-holder img {
  width: 100%;
}

/* -----------------------------------
    17 - Responsive Styles
------------------------------------*/
@media (max-width: 1200px) {
  body.menu-open {
    padding-left: 0;
  }

  .clients-section .clients-logos .logo-holder img {
    // max-height: 2rem;
    max-height: 10rem;
  }

  .post-box {
    margin-bottom: 3rem;
  }

  .post-box:last-child {
    margin-bottom: 0;
  }

  .post-box .text-holder {
    padding: 4rem 4rem;
  }

  .post-box .text-holder .title {
    font-size: 4rem;
    line-height: 2.8rem;
    margin-bottom: 1.2rem;
  }

  .post-box .text-holder .text {
    font-size: 1.2rem;
    line-height: 2rem;
  }

  .post-box .img-holder img {
    width: 23rem;
  }

  .post-section .post-header .post-info-holder {
    margin-bottom: 3rem;
    -moz-flex-direction: column;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .post-section .post-header .post-info-holder .post-info {
    text-align: left;
  }

  .post-section .post-header .text-holder .title {
    font-size: 3.6rem;
    line-height: 2.5rem;
    margin-bottom: 2.2rem;
  }

  .post-section .post-header .img-holder img {
    width: 24rem;
  }
}

@media (max-width: 992px) {
  .extra-lg-text {
    font-size: 4.8rem;
    line-height: 3.4rem;
  }

  .lg-text {
    font-size: 4rem;
    line-height: 2.8rem;
  }

  .normal-text p {
    font-size: 1.1rem;
    line-height: 1.8rem;
  }

  .slg-text {
    font-size: 2.6rem;
    line-height: 1.9rem;
    margin-bottom: 3rem;
  }

  .normal-lg-text p {
    font-size: 1.8rem;
    line-height: 2rem;
  }

  .boxy {
    padding: 5rem 6rem;
  }

  .boxy h1.title {
    font-size: 3rem;
    line-height: 2.4rem;
    margin-bottom: 2rem;
  }

  .boxy.img-box {
    padding: 3.2rem;
    min-height: 370px;
  }

  .boxy.img-box:before {
    background: initial;
    background-repeat: repeat-x;
    background-image: -webkit-linear-gradient(
      0deg,
      rgba(35, 41, 49, 0.6),
      rgba(35, 41, 49, 0)
    );
    background-image: -o-linear-gradient(
      0deg,
      rgba(35, 41, 49, 0.6),
      rgba(35, 41, 49, 0)
    );
    background-image: linear-gradient(
      0deg,
      rgba(35, 41, 49, 0.6),
      rgba(35, 41, 49, 0)
    );
    opacity: 1;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }

  .boxy .bottom-text .link {
    font-size: 1rem;
  }

  .boxy .bottom-text .link a {
    font-size: 1rem;
  }

  .boxy .bottom-text .text {
    font-size: 0.9rem;
  }

  .boxy.simple-data {
    padding: 7rem 6rem;
  }

  .clients-section {
    padding: 6rem 6.5rem 5rem;
  }

  .clients-section .clients-logos .logo-holder {
    -moz-flex: 1 1 25%;
    -webkit-flex: 1 1 25%;
    -ms-flex: 1 1 25%;
    flex: 1 1 25%;
    margin-bottom: 3rem;
  }

  .clients-section .clients-logos .logo-holder img {
    // max-height: 1.8rem;
    max-height: 10rem;
  }

  .process-section {
    padding: 6rem 6.5rem;
  }

  .process-section p {
    margin-bottom: 4rem;
  }

  .text-box {
    margin-bottom: 2rem;
  }

  .text-box .title {
    font-size: 2rem;
    margin-bottom: 0.6rem;
  }

  .text-box p {
    font-size: 1.1rem;
    line-height: 1.8rem;
  }

  .team-section {
    padding: 6rem 2rem 6rem;
  }

  .team-section .team-photos {
    bottom: -10.25rem;
    padding-bottom: 5rem;
  }

  .team-section .team-photos .photo-holder {
    margin: 0 1.5rem;
  }

  .team-section .team-photos .photo-holder img {
    width: 10rem;
  }

  .team-section .team-photos .photo-holder:nth-child(odd) {
    bottom: -2rem;
  }

  .jobs-section {
    padding: 6rem 6.5rem;
  }

  .jobs-section .lg-text {
    margin-bottom: 4rem;
  }

  .jobs-section .job-box-row {
    margin-bottom: 4rem;
  }

  .jobs-section .job-box-row:last-child {
    margin-bottom: 0;
  }

  .jobs-section .job-box .title {
    font-size: 2rem;
    line-height: 2.5rem;
    text-transform: capitalize;
  }

  .jobs-section .job-box .subtitle {
    font-size: 1.1rem;
    line-height: 2.5rem;
    margin-bottom: 0.5rem;
  }

  .jobs-section .btn-holder {
    text-align: right;
  }

  .jobs-section .btn-holder .cr-btn {
    padding: 1rem 2rem 0.7rem;
  }

  .padding-for-team {
    padding-top: 12rem !important;
  }

  .project-info {
    padding: 4rem 6.5rem 0;
  }

  .project-info h2 {
    font-size: 2rem;
    line-height: 1.8rem;
  }

  .project-info .normal-text {
    margin-bottom: 2rem;
  }

  .project-info .text-box .title {
    font-size: 1.9rem;
    margin-bottom: 0.2rem;
  }

  .blog-section {
    padding: 4rem 6.5rem;
  }

  .post-box {
    margin-bottom: 3rem;
  }

  .post-box:last-child {
    margin-bottom: 0;
  }

  .post-box .text-holder {
    padding: 4rem 3rem;
  }

  .post-box .text-holder .title {
    font-size: 2.9rem;
    line-height: 2rem;
    margin-bottom: 1.2rem;
  }

  .post-box .text-holder .text {
    font-size: 1rem;
    line-height: 1.8rem;
  }

  .post-box .img-holder img {
    width: 20rem;
  }

  .post-section .post-header .post-info-holder {
    margin-bottom: 3rem;
    -moz-flex-direction: column;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .post-section .post-header .post-info-holder .link-holder a {
    font-size: 1.4rem;
  }

  .post-section .post-header .post-info-holder .post-info {
    text-align: left;
  }

  .post-section .post-header .post-info-holder .post-info .date {
    font-size: 1.1rem;
  }

  .post-section .post-header .post-info-holder .post-info .auther {
    font-size: 1.1rem;
  }

  .post-section .post-header .text-holder {
    padding: 3rem 6.5rem 0rem;
  }

  .post-section .post-header .text-holder .title {
    font-size: 2.6rem;
    line-height: 2rem;
    margin-bottom: 2.2rem;
  }

  .post-section .post-header .text-holder .text {
    font-size: 1rem;
    line-height: 1.8rem;
  }

  .post-section .post-header .img-holder img {
    width: 20rem;
  }

  .post-section .post-content {
    padding: 3rem 6.5rem;
  }

  .post-section .post-content h1 {
    font-size: 2rem;
    line-height: 2rem;
    margin-bottom: 1rem;
  }

  .post-section .post-content p {
    font-size: 1rem;
    line-height: 1.8rem;
    margin-bottom: 3rem;
  }

  .our-people-section {
    padding: 6rem 6.5rem;
  }

  .our-people-section .extra-lg-text {
    margin-bottom: 5rem;
  }

  .our-people-section .lg-text {
    margin-bottom: 2rem;
  }

  .our-people-section .normal-text {
    margin-bottom: 3rem;
  }

  .our-people-section .people-box-row {
    margin-bottom: 3rem;
  }

  .our-people-section .people-box-row:last-child {
    margin-bottom: 0;
  }

  .our-people-section .people-box .title {
    font-size: 2rem;
    line-height: 2.5rem;
  }

  .our-people-section .people-box .subtitle {
    font-size: 1.2rem;
    line-height: 2rem;
    margin-bottom: 1rem;
  }

  .our-people-section .btn-holder {
    text-align: right;
  }

  .our-people-section .btn-holder .cr-btn {
    padding: 1.1rem 2rem 0.9rem;
  }
}

@media (max-width: 768px) {
  .menu-toggle {
    left: 2rem;
  }

  .main-menu {
    overflow: scroll;
    left: -100%;
  }

  .main-menu .menu-links ul li a {
    font-size: 4.8rem;
    line-height: 3rem;
  }

  .cnav {
    padding: 2rem 2rem;
  }

  .cnav .logo-holder img {
    width: 2.2em;
  }

  .cr-btn {
    font-size: 1.3rem;
  }

  .header {
    padding: 11.5rem 2rem 10rem;
  }

  .extra-lg-text {
    font-size: 3.8rem;
    line-height: 2.6rem;
  }

  .lg-text {
    font-size: 2.6rem;
    line-height: 1.9rem;
  }

  .normal-text p {
    font-size: 1rem;
    line-height: 1.8rem;
  }

  .boxy {
    padding: 5rem 5rem;
  }

  .clients-section {
    padding: 6rem 5rem 5rem;
  }

  .clients-section .clients-logos .logo-holder img {
    // max-height: 1.5rem;
    max-height: 10rem;
  }

  .default-content {
    padding: 10rem 2rem 8rem;
  }

  .other-content {
    padding: 6rem 2rem;
  }

  .footer {
    padding: 6rem 2rem 6rem;
  }

  .footer .contact-info-holder {
    text-align: center;
  }

  .footer .contact-info-holder .title {
    font-size: 1.8rem;
  }

  .footer .contact-info-holder .contact-info {
    font-size: 2.2rem;
    margin-bottom: 3rem;
  }

  .footer .contact-info-holder .contact-info a {
    font-size: 2.2rem;
  }

  .footer .contact-info-holder .social-media .social-link-holder a {
    font-size: 0.9rem;
  }

  .footer.one-word {
    padding: 8rem 8rem 7rem;
  }

  .process-section {
    padding: 6rem 5rem;
  }

  .process-section p {
    margin-bottom: 4rem;
  }

  .text-box {
    margin-bottom: 2rem;
  }

  .text-box .title {
    font-size: 2rem;
    margin-bottom: 0.6rem;
  }

  .text-box p {
    font-size: 1rem;
    line-height: 1.8rem;
    margin-bottom: 2rem;
  }

  .team-section {
    padding: 6rem 2rem 6rem;
  }

  .team-section .team-photos {
    bottom: -8.25rem;
    padding-bottom: 5rem;
  }

  .team-section .team-photos .photo-holder {
    margin: 0 1rem;
  }

  .team-section .team-photos .photo-holder img {
    width: 8rem;
  }

  .team-section .team-photos .photo-holder:nth-child(odd) {
    bottom: -2rem;
  }

  .jobs-section {
    padding: 6rem 5rem 6rem;
  }

  .padding-for-team {
    padding-top: 8rem !important;
  }

  .project-info {
    padding: 4rem 5rem 0;
  }

  .project-imgs {
    padding: 2rem;
  }

  .project-imgs .img-holder {
    margin-bottom: 2.5rem;
  }

  .blog-section {
    padding: 3rem 5rem;
  }

  .post-box {
    margin-bottom: 3rem;
    -moz-flex-direction: column-reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  .post-box:last-child {
    margin-bottom: 0;
  }

  .post-box .text-holder {
    padding: 3rem;
  }

  .post-box .img-holder img {
    width: 100%;
  }

  .post-section .post-header {
    -moz-flex-direction: column-reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  .post-section .post-header .text-holder {
    padding: 3rem 5rem 0rem;
  }

  .post-section .post-header .text-holder .title {
    font-size: 2.9rem;
  }

  .post-section .post-header .img-holder img {
    width: 100%;
  }

  .post-section .post-content {
    padding: 3rem 5rem;
  }

  .few-contact {
    padding: 4rem 2rem;
  }

  .few-contact .contact-info-holder {
    text-align: center;
  }

  .few-contact .contact-info-holder .title {
    font-size: 1.8rem;
  }

  .few-contact .contact-info-holder .contact-info {
    font-size: 2.2rem;
    margin-bottom: 0;
  }

  .few-contact .contact-info-holder .contact-info a {
    font-size: 2.2rem;
  }

  .our-people-section {
    padding: 6rem 5rem;
  }

  .our-people-section .extra-lg-text {
    margin-bottom: 4rem;
  }

  .our-people-section .lg-text {
    margin-bottom: 2rem;
  }

  .our-people-section .normal-text {
    margin-bottom: 3rem;
  }

  .our-people-section .people-box-row {
    margin-bottom: 3rem;
  }

  .our-people-section .people-box-row:last-child {
    margin-bottom: 0;
  }

  .our-people-section .people-box .title {
    font-size: 2rem;
    line-height: 2.5rem;
  }

  .our-people-section .people-box .subtitle {
    font-size: 1.2rem;
    line-height: 2rem;
    margin-bottom: 1rem;
  }

  .our-people-section .btn-holder {
    text-align: right;
  }

  .our-people-section .btn-holder .cr-btn {
    padding: 1.1rem 2rem 0.9rem;
  }

  .social-row {
    padding: 5rem 5.5rem;
  }

  .social-row .social-media .social-link-holder a {
    font-size: 1.5rem;
  }
}

/* ****************************************************************************************************** */
@media (max-width: 575px) {
  .menu-toggle {
    left: inherit;
    right: 1.5rem;
    top: 3.1rem;
  }

  .menu-toggle.open {
    left: inherit;
    right: 1.5rem;
    top: 3.1rem;
  }

  .main-menu {
    width: 100%;
    padding: 2rem;
    text-align: left;
  }

  .main-menu .menu-links {
    margin-bottom: 4rem;
  }

  .main-menu .menu-links ul li {
    overflow: hidden;
    padding-top: 0.5rem;
  }

  .main-menu .menu-links ul li a {
    font-size: 4.8rem;
    line-height: 3rem;
  }

  .main-menu .social-media .social-link-holder {
    text-align: left;
  }

  .cnav {
    padding: 2rem 1.5rem;
  }

  .cnav .logo-holder img {
    width: 2rem;
  }

  .cnav .social-media {
    display: none;
  }

  .extra-lg-text {
    font-size: 2.8rem;
    line-height: 2rem;
  }

  .boxy {
    padding: 5rem 3.5rem;
  }

  .clients-section {
    padding: 6rem 2rem;
  }

  .clients-section .clients-logos .logo-holder {
    -moz-flex: 1 1 50%;
    -webkit-flex: 1 1 50%;
    -ms-flex: 1 1 50%;
    flex: 1 1 50%;
    margin-bottom: 3rem;
  }

  .clients-section .clients-logos .logo-holder img {
    // max-height: 1.5rem;
    max-height: 10rem;
  }

  .footer .contact-info-holder .social-media .social-link-holder a {
    font-size: 0.7rem;
  }

  .process-section {
    padding: 6rem 2rem;
  }

  .process-section > .row {
    margin-right: 0;
    margin-left: 0;
  }

  .process-section > .row > div[class^="col"] {
    padding-right: 0;
    padding-left: 0;
  }

  .jobs-section {
    padding: 6rem 2rem 6rem;
  }

  .jobs-section .btn-holder {
    text-align: left;
  }

  .project-info {
    padding: 4rem 2rem 0;
  }

  .project-imgs .img-holder {
    margin-bottom: 1rem;
  }

  .blog-section {
    padding: 2rem;
  }

  .post-box .text-holder {
    padding: 2rem;
  }

  .post-section .post-header .text-holder {
    padding: 3rem 2rem 0rem;
  }

  .post-section .post-content {
    padding: 3rem 2rem;
  }

  .our-people-section {
    padding: 6rem 2rem;
  }

  .our-people-section .btn-holder {
    text-align: left;
  }

  .social-row .social-media .social-link-holder a {
    font-size: 2rem;
  }
}
